import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BounceLoader from "react-spinners/ClipLoader";
import RespondentFlowApi from "../services/respondentFlow.api";
import { updateStatus } from "../services/respondent.api";
import * as StatusConstants from "../constants/status.constants";
import LoggerService from "../services/logger.service";
import UILoggerService from "../services/ui.logger.service";

const Start = () => {
  const [Loader] = useState(true);
  const [ErrorMessage] = useState("Loading...");

  let navigate = useNavigate();

  let getStatus = async () => {
    let storedParam = JSON.parse(sessionStorage.getItem("params"));
    try {
      let reqObj = {};

      reqObj.traceId = storedParam.traceId;
      reqObj.userUid = storedParam.userUid;
      reqObj.surveyUid = storedParam.surveyUid;
      reqObj.vendorUid = storedParam.vendorUid;
      reqObj.token = storedParam.token;
      reqObj.tokenId = storedParam.tokenId;
      reqObj.sessionUid = storedParam.sessionUid;
      reqObj.paramList = storedParam.paramList;

      LoggerService.log(
        storedParam.token,
        "",
        "Calling routing service",
        JSON.stringify(reqObj)
      );

      let resp = await RespondentFlowApi.post("/v1/survey/router/", reqObj);
      LoggerService.log(
        storedParam.token,
        "",
        "Router response received",
        JSON.stringify(resp)
      );
      if (resp.isInternalRedirect) {
        let obj = {};
        obj.traceId = resp.traceId ? resp.traceId : ""; //TODO:check trace id in response
        obj.paramList = resp.paramList;
        obj.sessionUid = resp.sessionUid;
        obj.surveyUid = resp.surveyUid;
        obj.vendorUid = resp.vendorUid;
        obj.token = resp.token;
        obj.tokenId = resp.tokenId;
        obj.userUid = resp.userUid;
        obj.userId = resp.userId;
        sessionStorage.removeItem("params");
        sessionStorage.setItem("params", JSON.stringify(obj));
        LoggerService.log(
          resp.token,
          "",
          "Routing to survey screener",
          `/screener?token=${resp.token}`
        );
        navigate(`/screener?token=${resp.token}`);
      } else {
        sessionStorage.removeItem("params");
        if (resp.redirectType === 3) {
          await updateStatus(
            resp.token,
            resp.tokenId,
            StatusConstants.STATUS_SUPPLIER_REDIRECT_START,
            StatusConstants.SUB_STATUS_EXTERNAL_SUPPLIER_REDIRECT_DONE
          );
          LoggerService.log(
            resp.token,
            "",
            "Redirecting to supplier url " + `${resp.redirectUrl}`,
            `${resp.redirectUrl}`
          );
        } else if (resp.redirectType === 4) {
          await updateStatus(
            resp.token,
            resp.tokenId,
            StatusConstants.STATUS_INTERNAL_CLIENT_REDIRECT_START,
            StatusConstants.SUB_STATUS_EXTERNAL_REDIRECT_DONE
          );
          LoggerService.log(
            resp.token,
            "",
            "Redirecting to client survey url " + `${resp.redirectUrl}`,
            `${resp.redirectUrl}`
          );
        }
        // LoggerService.log(
        //   resp.token,
        //   "",
        //   "Router external redirect received " + JSON.stringify(resp)
        // );
        window.location.href = `${resp.redirectUrl}`;
      }
    } catch (e) {
      console.error(e);
      //LoggerService.log(storedParam.token, "", "Error in router ", e.message);
      
      UILoggerService.sendLog(window.location.href,'Start GetStatus',e).then(() => {
        navigate(`/error`);
      }).catch(() => {
        navigate(`/error`);
      })
    }
  };

  useEffect(() => {
    getStatus();
  }, []);

  return (
    <>
      {Loader ? (
        <>
          <div className="flex justify-center items-center h-screen bg-gray-200">
            <div>
              {/* //change here color */}
              <BounceLoader
                color="rgb(129, 176, 60)"
                loading={true}
                size={60}
                textalign="center"
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              {/* //change here color */}
              <span
                style={{
                  fontWeight: "700",
                  marginTop: "20px",
                  display: "block",
                  textalign: "center",
                  color: "rgb(129, 176, 60)",
                }}
              >
                {ErrorMessage}
              </span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex  justify-center">
            <img
              src="https://img.icons8.com/bubbles/256/man-with-a-clock.png"
              alt=""
            />
          </div>
        </>
      )}
    </>
  );
};

export default Start;
