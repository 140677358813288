import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BounceLoader from "react-spinners/ClipLoader";
import axios from "axios";
import useQueryParams from "../utils/query.params";
import RespondentFlowApi from "../services/respondentFlow.api";
import UILoggerService from "../services/ui.logger.service";

const DynataEnding = () => {
  const [Loader] = useState(true);
  const [ErrorMessage, setErrorMessage] = useState("Loading");
  const [paramActive, setParamActive] = useState(false);
  let params = {};
  useQueryParams().forEach((value, key) => {
    params[key] = value;
  });
  useEffect(() => {
    if (params) {
      if (paramActive === false) setParamActive(true);
    }
    // eslint-disable-next-line
  }, [params]);

  let navigate = useNavigate();

  let processDynataParams = (params) => {
    params["eid"] = params["respondent_id"] || params["eid"];
    switch (params["disposition"]) {
      case (0, 4, 5):
        params["estsid"] = 4;
        break;

      default:
        params["estsid"] = params["disposition"];
        break;
    }
    return params;
  };

  useEffect(() => {
    if (paramActive === true) {
      try {
        params["url"] = window.location.href;
        let paramList = processDynataParams(params);
        RespondentFlowApi
          .post("/v1/ending/", paramList, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then(async (data) => {
            if (data.result === "not_ok") {
              navigate(`/error?token=${params.token}`);
              return;
            } else if (data.hasError) {
              setErrorMessage(data.messages[0]);
              return;
            } else {
              if (data.isInternalRedirect) {
                let obj = {};
                obj.traceId = data.traceId;
                obj.paramList = data.paramList;
                obj.sessionUid = data.sessionUid;
                obj.surveyUid = data.surveyUid;
                obj.vendorUid = data.vendorUid;
                obj.token = data.token;
                obj.tokenId = data.tokenId;
                obj.userUid = data.userUid;
                obj.userId = data.userId;
                sessionStorage.removeItem("params");
                sessionStorage.setItem("params", JSON.stringify(obj));
                navigate(`/screener?token=${obj.token}`);
              } else {
                sessionStorage.removeItem("params");
                window.location.href = `${data.redirectUrl}`;
              }
            }
          })
          .catch((e) => {
            console.error(e);
            UILoggerService.sendLog(window.location.href,'DynataEndingAPIResp Error',e).then(() => {
              navigate(`/error`);
            }).catch(() => {
              navigate(`/error`);
            })
          });
      } catch (err) {
        UILoggerService.sendLog(window.location.href,'DynataEndingAPIResp Error',err).then(() => {
          navigate(`/error`);
        }).catch(() => {
          navigate(`/error`);
        })
      }
    }
    // eslint-disable-next-line
  }, [paramActive]);

  return (
    <>
      {Loader ? (
        <>
          <div className="flex justify-center items-center h-screen bg-gray-200">
            <div>
              <BounceLoader
                color="rgb(129, 176, 60)"
                loading={true}
                size={60}
                textalign="center"
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              <span
                style={{
                  fontWeight: "700",
                  marginTop: "20px",
                  display: "block",
                  textalign: "center",
                  color: "rgb(129, 176, 60)",
                }}
              >
                {ErrorMessage}
              </span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex  justify-center">
            <img
              src="https://img.icons8.com/bubbles/256/man-with-a-clock.png"
              alt=""
            />
          </div>
        </>
      )}
    </>
  );
};

export default DynataEnding;
