import environment from "../environments/environment.json";
import RespondentFlowApi from "./respondentFlow.api";

const log = async (token, supplierToken, message, meta = "") => {
  // if (!environment.SERVER_LOG_SYNC) {
  //   return;
  // }
  //Api call to send log to server
  let payload = {};
  payload.token = token;
  payload.supplier_token = supplierToken;
  payload.message = message;
  payload.meta_data = meta;
  payload.log_time = new Date();

  try {
    await RespondentFlowApi.post("/v1/landing/save/log", payload);
  } catch (e) {
    console.error(e);
  }
};

const LoggerService = {
  log,
};

export default LoggerService;
