import environment from "../environments/environment.json";
import RespondentFlowApi from "./respondentFlow.api";

const sendLog = async (url, logLocation, meta) => {
  // if (!environment.SERVER_LOG_SYNC) {
  //   return;
  // }
  //Api call to send log to server
  let payload = {};
  payload.url = url;
  payload.log_location = logLocation;
  payload.meta_data = JSON.stringify(meta);
  payload.log_time = new Date();

  try {
    await RespondentFlowApi.post("/v1/ending/save/log", payload);
  } catch (e) {
    console.error(e);
  }
};

const UILoggerService = {
  sendLog,
};

export default UILoggerService;
